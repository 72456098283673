const positionsMapping = {
    1: 'Bereifung vorne links',
    2: 'Kotflügel vorne links',
    3: 'Radlauf vorne links',
    4: 'A-Säule links',
    5: 'Fahrer-Tür',
    6: 'Schweller links',
    7: 'Tür hinten links',
    8: 'Kotflügel/Seitenwand hinten links',
    9: 'Radlauf hinten links',
    10: 'Reling links',
    11: 'Seitenspiegel links',
    12: 'Fenster Fahrerseite',
    13: 'Fenster hinten links',
    14: 'Bereifung hinten links',
    15: 'Bereifung vorne rechts',
    16: 'Kotflügel vorne rechts',
    17: 'Radlauf vorne rechts',
    18: 'A-Säule rechts',
    19: 'Beifahrer-Tür',
    20: 'Tür hinten rechts',
    21: 'Schweller rechts',
    22: 'Radlauf hinten rechts',
    23: 'Kotflügel/Seitenwand hinten rechts',
    24: 'Reling rechts',
    25: 'Seitenspiegel rechts',
    26: 'Fenster Beifahrerseite',
    27: 'Fenster hinten rechts',
    28: 'Bereifung hinten rechts',
    29: 'Scheinwerfer links',
    30: 'Stoßfänger vorne links',
    31: 'Frontschürze links',
    32: 'Frontschürze rechts',
    33: 'Stoßfänger vorne rechts',
    34: 'Scheinwerfer rechts',
    35: 'Kennzeichen vorne',
    36: 'Stoßfänger hinten links',
    37: 'Leuchte links',
    38: 'Stoßfänger hinten rechts',
    39: 'Leuchte rechts',
    40: 'Kennzeichen hinten',
    41: 'Heckschürze',
    42: 'Kofferraumdeckel',
    43: 'Heckscheibe',
    44: 'Dach',
    45: 'Frontscheibe',
    46: 'Motorhaube',
    47: 'Vordere linke felge',
    48: 'Vordere rechte felge',
    49: 'Hintere linke felge',
    50: 'Hintere rechte felge',
    51: 'Schaden am Unterboden'
}

export default positionsMapping
