import { render, staticRenderFns } from "./Damages.vue?vue&type=template&id=3372a41a&scoped=true&"
import script from "./damages.js?vue&type=script&lang=js&"
export * from "./damages.js?vue&type=script&lang=js&"
import style0 from "./damages.scss?vue&type=style&index=0&id=3372a41a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3372a41a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
installComponents(component, {VAlert})
