import { mapGetters } from 'vuex'
import PreviewImage from '../../previewImage/PreviewImage.vue'
import EventBus from '../../../utils/eventBus'
import FormCheck from '../../form/formCheck/FormCheck.vue'
import { typesToNames } from '../../../utils/index'

export default {
    name: 'ListDamages',

    components: {
        PreviewImage,
        FormCheck
    },

    props: {
        view: {
            type: String,
            default: null
        },

        isSummary: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            noDamages: null
        }
    },

    mounted() {
        const damagesContainerNode = document.querySelector('.damagesContainer')
        const damagesListNode = document.querySelector(
            '.damagesContainer .damagesList'
        )
        let damagesContainerHeight = damagesContainerNode.clientHeight

        // Change height on mobile
        if (window.innerWidth < 460) {
            damagesContainerHeight = 250
        }

        damagesListNode.style.height = damagesContainerHeight + 'px'
    },

    computed: {
        ...mapGetters({
            form: 'form/form'
        }),

        selectedDamages() {
            return this.form.damages.selectedDamages
        },

        selectedDamagesTypeNames() {
            let typeNames = []
            this.form.damages.selectedDamages.forEach((selectedDamage) => {
                const types = selectedDamage.type.split(',')
                let typeName = []
                types.forEach((type) => {
                    typeName.push(this.typeNames[type])
                })
                typeNames[selectedDamage.type] = typeName.join(', ')
            })
            return typeNames
        },

        typeNames() {
            return typesToNames()
        }
    },

    methods: {
        editDamage(pos) {
            EventBus.$emit('editDamage', pos)
        }
    }
}
