import EventBus from '../../../utils/eventBus'
import FormRadio from '../../form/formRadio/FormRadio.vue'
import FormTextarea from '../../form/formTextarea/FormTextarea.vue'
import damageTypes from '../../../constants/damageTypes'
import { typesToNames } from '../../../utils/index'
import PreviewImage from '../../previewImage/PreviewImage.vue'
import { resizePhoto } from '../../../constants/file'
import FormCheck from '../../form/formCheck/FormCheck'

export default {
    name: 'DamagesDialog',

    components: {
        FormRadio,
        FormTextarea,
        FormCheck,
        PreviewImage
    },

    props: ['showDamageDialog', 'position', 'selectedDamage'],

    data() {
        return {
            showDialog: true,
            type: null,
            selectedTypes: {},
            typeValues: damageTypes,
            description: null,
            photos: [],
            showRemoveDamageDialog: false
        }
    },

    created() {
        if (this.selectedDamage) {
            let selectedTypes = this.selectedDamage.type.split(',')
            selectedTypes.forEach((st) =>{
                this.selectedTypes[st] = true
            })
            this.description = this.selectedDamage.description
            this.photos = [...this.selectedDamage.photos]
        }
    },

    mounted() {
        // Fix for v-file-input not triggering properly
        // https://github.com/vuetifyjs/vuetify/issues/10832
        const inputs = document.querySelectorAll('.v-file-input input')
        ;[...inputs].forEach(input => {
            input.remove()
        })
    },

    computed: {
        validate() {
            let selected = false
            this.typeValues.forEach((selectedType)=>{
                if (this.selectedTypes[selectedType.value]){
                    selected = true
                }
            })
            return selected && this.position
        },

        typeNames() {
            return typesToNames()
        }
    },

    methods: {
        getSelectedValues(){
            return Object.keys(this.selectedTypes).filter((st)=>{return this.selectedTypes[st] === true});
        },
        save() {
            if (this.validate) {
                const payload = {
                    type: this.getSelectedValues().join(','),
                    position: {
                        pos: this.position.pos,
                        name: this.position.name
                    },
                    description: this.description || null,
                    photos: this.photos,
                    photosAsBase64: []
                }

                if (this.selectedDamage) {
                    EventBus.$emit('updateDamage', payload)
                } else {
                    EventBus.$emit('addDamage', payload)
                }
            }
            if (this.getSelectedValues().length === 0){
                this.removeDamage()
            }
            this.hide()
        },
        deleteDamagePhoto(index) {
            this.photos.splice(index, 1)
        },
        hide() {
            this.$emit('update:showDamageDialog', false)
        },
        resetPhoto() {
            // Reset old photo to always trigger watcher
            this.$refs.damageCameraPhoto.value = null
        },
        async addDamagePhoto() {
            let resizedPhoto

            const photo = this.$refs.damageCameraPhoto.files[0] || null

            try {
                resizedPhoto = await resizePhoto(photo)
            } catch {
                // Just catching
            }

            if (resizedPhoto) {
                this.photos.push(resizedPhoto)
            }
        },
        removeDamage() {
            const payload = {
                position: {
                    pos: this.position.pos,
                    name: this.position.name
                }
            }
            EventBus.$emit('removeDamage', payload)

            this.showRemoveDamageDialog = true
            this.hide()
        }
    }
}
