import stepMixin from '../../../mixins/stepMixin'
import checkTaskMixin from '../../../mixins/checkTaskMixin'
import FormRadio from '../../form/formRadio/FormRadio.vue'
import FormSelect from '../../form/formSelect/FormSelect.vue'
import SelectDamages from '../../damages/selectDamages/SelectDamages.vue'
import ListDamages from '../../damages/listDamages/ListDamages.vue'
import EventBus from '../../../utils/eventBus'

export default {
    name: 'Damages',

    props: {
        stepIsValid: {
            type: Boolean,
            required: true
        }
    },

    mixins: [stepMixin, checkTaskMixin],

    components: {
        FormRadio,
        FormSelect,
        SelectDamages,
        ListDamages
    },

    data() {
        return {
            damagesType: null,
            damagesTypesValues: [
                {
                    label: 'TÜV vor Ort',
                    value: 'tuev'
                },
                {
                    label: 'Schäden selbst erfassen',
                    value: 'record'
                }
            ],
            transferNotes: null,
            transferNotesValues: [
                ' ',
                'Fahrzeug nass',
                'Fahrzeug stark verschmutzt',
                'Fahrzeug vereist oder verschneit'
            ]
        }
    },

    mounted() {
        // If this task is customer checkout, it will be set to type record.
        // The user does not need to select the damage type.
        if (this.isCheckout) {
            EventBus.$emit('setForm', {
                key: 'damagesType',
                value: 'record',
                step: 'damages'
            })
        }
    },

    computed: {
        damagesMessage() {
            return this.field.damagesType === 'record'
                ? 'Lieber Kunde. Basis für die ' +
                      'Berechnung von Nachforderungen ' +
                      'sind die hier im Protokoll erfassten ' +
                      'Positionen. Die Schadenerfassung bei ' +
                      'Übergabe mit dem Mitarbeiter/Fahrer ' +
                      'ist für den Kunden verbindlich.'
                : 'Lieber Kunde. Basis für die ' +
                      'Berechnung von Nachforderungen ' +
                      'durch fehlende Gegenstände sind ' +
                      'die hier im Protokoll erfassten ' +
                      'Positionen. Basis für die Berechnung ' +
                      'von Nachforderungen bei Schadensfällen ' +
                      'sind die im TÜV-Gutachten erfassten ' +
                      'Positionen.'
        },

        isCustomerCheckout() {
            return this.isCheckout && this.field.damagesType === 'record'
        }
    },

    methods: {
        validate() {
            let isValid = true

            if (this.isEmpty(this.field.damagesType)) {
                isValid = false
            }

            if (
                this.field.damagesType === 'record' &&
                this.field.selectedDamages.length === 0 &&
                (this.isEmpty(this.field.noDamages) || !this.field.noDamages)
            ) {
                isValid = false
            }

            this.$emit('update:stepIsValid', isValid)
        }
    }
}
