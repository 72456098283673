import { mapGetters } from 'vuex'
import DamagesDialog from '../../dialog/damages/Damages.vue'
import positionsMapping from '../../../constants/positionsMapping'
import EventBus from '../../../utils/eventBus'

export default {
    name: 'SelectDamages',

    components: {
        DamagesDialog
    },

    props: {
        view: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            showDamageDialog: false,
            selectedPosition: {}
        }
    },

    created() {
        EventBus.$on('editDamage', pos => {
            this.editDamage(pos)
        })
    },

    computed: {
        ...mapGetters({
            form: 'form/form'
        })
    },

    methods: {
        posHasDamage(pos, returnObj = false) {
            // Search for position in damages
            const damage =
                this.form.damages &&
                this.form.damages.selectedDamages &&
                this.form.damages.selectedDamages.find(
                    d => d.position.pos === pos
                )
            if (damage) {
                if (returnObj) {
                    return Object.assign({}, damage)
                }
                return true
            }

            if (returnObj) {
                return null
            }

            return false
        },

        editDamage(pos) {
            // Do not show dialog if we are in summary view
            if (this.view === 'summary') {
                return
            }

            // Map id to name
            const positionName = positionsMapping[pos]
                ? positionsMapping[pos]
                : 'Position ' + pos
            this.selectedPosition = {
                pos,
                name: positionName
            }

            this.showDamageDialog = true
        }
    },

    beforeDestroy() {
        EventBus.$off('editDamage')
    }
}
